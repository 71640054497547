import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 有赞
import Vant from 'vant'
import 'vant/lib/index.css'
import {
	Lazyload
} from 'vant';
import { Popup } from 'vant';

createApp(App)
	.use(Popup)
	.use(store)
	.use(router)
	.use(Vant)
	.use(Lazyload)
	.mount('#app')
	
	
