import { setToken, hasToken, removeToken } from '@/utils/token'
import config from '@/utils/config'
import router from '@/router'
import { isUnionPay, parseQuery } from '@/utils/tools'
import { auth,binding,unbinding } from '@/api/login'

const state = {
  isLogin: false
}

// 设置token
const mutations = {
  SET_TOKEN: (state, token) => {
    setToken(token)
  },
  SET_IS_LOGIN: (state, status) => {
    state.isLogin = status
  }
}

// 方法
const actions = {
  // 登录操作
  ToLogin({ commit }, redirect) {
    // 尝试从本地恢复登录
    if (hasToken()) {
      commit('SET_IS_LOGIN', true)
      return true
    }
    redirect = !redirect ? window.location.href : redirect
    // 替换掉重复的login
    if (isUnionPay()) { // 云闪付环境 
      const param = parseQuery(redirect)
      if (param.code) {
        // todo 获取code后登录初始化 ...
        auth({ code: param.code, appid: config.appId }).then((res)=>{
          commit('SET_TOKEN', res.data.token)
          commit('SET_IS_LOGIN', true)
        })
		binding({code: param.code}).then((res)=>{
			alert("绑定成功")
		})
      } else {
        window.location.href = "https://open.95516.com/s/open/html/oauth.html?appId=" + config.appId + "&redirectUri=" + redirect + "&responseType=code&scope=upapi_mobile&state=01"
      }
    } else { // 非云闪付环境 
      if (redirect.indexOf(window.origin+'/login?redirect=') !== -1) redirect.replaceAll(window.origin+'/login?redirect=','')
      router.push({
        path: '/login',
        query: { redirect }
      })
    }
  },
  // 本地退出
  LoginOut() {
    return new Promise((resolve) => {
      // 删除掉token
      removeToken()
      resolve()
    })
  }
}

export default {
  state,
  mutations,
  actions
}
