// 接口域名
// const APP_BASE_URL = 'http://192.168.1.38:666/'
// const APP_BASE_URL = 'https://server.esw235.cn/'
const APP_BASE_URL = 'https://shop2.esw235.cn/'
// const APP_BASE_URL = 'http://ds.yl0203.cc/'

// const APP_BASE_URL = 'https://server.esw235.cn/'
// const APP_BASE_URL = 'http://ds.yl0203.cc/'
// const APP_BASE_URL = 'http://qk.pj0322.xin/'
// const APP_BASE_URL = 'http://ds.yl0303.cc/'
// const APP_BASE_URL = 'https://ysf.leapy.cn/'
// const APP_WS_URL = 'ws://182.254.133.82:2001/'
const APP_WS_URL = 'ws://127.0.0.1:2001/'

export default {
  imageUrl:APP_BASE_URL,
  server: APP_BASE_URL + 'mobile/',
  ws: APP_WS_URL,
  // appId
  appId: 'a8c117dd0d644622a1b26034b63aff55',
}
