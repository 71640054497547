import { getToken } from '@/utils/token'
import config from '@/utils/config'

const server = function() {
  this.ws = new WebSocket(config.ws)
  this.ws.onopen = this.onopen.bind(this)
  this.ws.onmessage = this.onmessage.bind(this)
  this.ws.onclose = this.onclose.bind(this)
}

// 添加方法
server.prototype = {
  onopen() {
    this.init()
    this.login()
  },
  channel(channel) {
    this.cn = channel
  },
  init() {
    // 定时器心跳
    const that = this
    this.timer = setInterval(function() {
      that.ws.send(JSON.stringify({
        'type': 'ping',
        'data': {}
      }))
    }, 20000)
  },
  login() {
    // 登录
    this.ws.send(JSON.stringify({
      'type': 'login',
      'platform': 's',
      'data': getToken()
    }))
  },
  send(data) {
    // 发送信息
    console.log(data)
    return this.ws.send(JSON.stringify(data))
  },
  onclose() {
    // 关闭清除定时器
    clearInterval(this.timer)
  },
  close() {
    clearInterval(this.timer)
    this.ws.close()
  },
  onmessage(res) {
    const { type, data = {}} = JSON.parse(res.data)
    this.cn.emit(type, data)
  }
}

server.prototype.constructor = server

export default server
