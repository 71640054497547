import router from '@/router'

const BROWSER_CORE = 'com.unionpay'

// 判断是否是云闪付环境
export const isUnionPay = () => {
  return navigator.userAgent.indexOf(BROWSER_CORE) > -1
}

// 解析参数
export const parseQuery = (query = '') => {
  const param = {}
  query = !query ? window.location.href : query
  query = query.substring(query.indexOf('?') + 1)
  if (query.indexOf('?') === 0) {
    query = query.substring(1)
  }
  const parts = query.split('&')
  for (let i = 0; i < parts.length; i++) {
    const eIndex = parts[i].indexOf('=')
    if (eIndex > 0) {
      const key = parts[i].substr(0, eIndex)
      param[key] = decodeURIComponent(parts[i].substr(eIndex + 1))
    }
  }
  return param
}

/**
 * url 生成
 * @param path
 * @returns {string}
 */
export const buildUrl = (path = '') => {
  if (path[0] === '/') return window.origin + path
  else return window.origin + '/' + path
}

/**
 * 跳转 url
 * @param path
 * @param param
 */
export const toUrl = (path='', param = '') => {
  router.push({
    path: path,
    query: param
  })
}
