<template>
<van-tabbar
    v-if="type === 2"
    v-model="active"
    active-color="#ee0a24"
    :route="true"
  >
    <van-tabbar-item
      v-for="(tab, key) in tabs1"
      :key="key"
      :name="tab.name"
      :to="tab.to"
      :icon="tab.icon"
    >
      {{ tab.title }}
    </van-tabbar-item>
  </van-tabbar>
  <van-tabbar
    v-else
    v-model="active"
    active-color="#ee0a24"
    :route="true"
  >
    <van-tabbar-item
      v-for="(tab, key) in tabs"
      :key="key"
      :name="tab.name"
      :to="tab.to"
      :icon="tab.icon"
    >
      {{ tab.title }}
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { judge } from '@/api/merchant'

export default {
  name: 'Footer',
  data() {
    return {
      type:1,
      active: 'Home',
      tabs: [
        {
          'name': 'Home',
          'to': '/',
          'icon': 'wap-home-o',
          'title': '首页'
        }, {
          'name': 'Chat',
          'to': '/chat',
          'icon': 'friends-o',
          'title': '客服'
        }, {
          'name': 'Mine',
          'to': '/mine',
          'icon': 'contact',
          'title': '我的'
        }
      ],
      tabs1: [
        {
          'name': 'Home',
          'to': '/statistics',
          'icon': 'wap-home-o',
          'title': '统计'
        }, {
          'name': 'store',
          'to': '/store',
          'icon': 'friends-o',
          'title': '门店'
        }, {
          'name': 'Mine',
          'to': '/mine',
          'icon': 'contact',
          'title': '我的'
        }
      ]
    }
  },
  watch: {
    $route(to, from) {
      console.log(this.$route.path);
      if(this.$route.path!=="/login"){
        judge().then((res) => {
          this.type = res.data.type
          console.log(this.type)
        })
      }
    
    }
  },
}
</script>

<style scoped>

</style>
