import request from '@/utils/request'

export function statis(params) {
    return request({
        url: '/merchant/statis',
        method: 'get',
        params
    })
}
export function judge(params) {
  return request({
      url: '/judge',
      method: 'get',
      params,

  })
}
export function storeList(params) {
  return request({
      url: '/store/lst',
      method: 'get',
      params,

  })
}
export function storeInfo(params) {
  return request({
      url: '/store/storeinfo',
      method: 'get',
      params,

  })
}
export function edit(data) {
  return request({
      url: '/store/edit',
      method: 'POST',
      data,

  })
}
export function trade(params) {
  return request({
      url: '/store/trade',
      method: 'get',
      params,

  })
}
export function options(params) {
  return request({
      url: '/area/options',
      method: 'get',
      params,

  })
}