import vueCookie from 'vue-cookies'

const TokenKey = 'Token'
const expire_time = 60 * 60 * 6

export function getToken() {
  return vueCookie.get(TokenKey)
}

export function setToken(token) {
  return vueCookie.set(TokenKey, token, expire_time)
}

export function removeToken() {
  return vueCookie.remove(TokenKey)
}

export function hasToken() {
  return vueCookie.isKey(TokenKey)
}
