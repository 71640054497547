import request from '@/utils/request'

// 登录方法 ok
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    isLogin: false,
    data: data
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 授权登录
export function auth(data) {
  return request({
    url: '/auth',
    method: 'post',
    isLogin: false,
    data: data
  })
}

// 初始化
export function config(data) {
  return request({
    url: '/config',
    method: 'post',
    isLogin: false,
    data: data
  })
}

// 云闪付绑定 
export function binding(data) {
  return request({
    url: '/index/binding',
    method: 'post',
    isLogin: false,
    data: data
  })
}

// 云闪付解绑
export function unbinding(data) {
  return request({
    url: '/index/unbinding',
    method: 'post',
    isLogin: false,
    data: data
  })
}
//商户门店列表
export function storeLst(params) {
  return request({
      url: '/store/lst',
      method: 'get',
      params,
  })
}
//登录到门店
export function logins(data) {
  return request({
    url: '/logins',
    method: 'post',
    // isLogin: false,
    data: data
  })
}