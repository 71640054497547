import { createRouter, createWebHistory } from 'vue-router'
import $store from '../store'
import { buildUrl } from '@/utils/tools'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { footer: true, title: '首页', isLogin: true  },
    component: () => import('../views/home/Home.vue')
  },
  {
    path: '/home/details',
    name: 'homeDetails',
    meta: { title: '首页', isLogin: true  },
    component: () => import('../views/home/homeDetails.vue')
  },
  {
    path: '/cate/list',
    name: 'CateList',
    meta: { title: '分类管理', isLogin: true },
    component: () => import('../views/cate/CateList.vue')
  },
  {
    path: '/line/list',
    name: 'LineList',
    meta: { title: '排队', isLogin: true },
    component: () => import('../views/line/lineUp.vue')
  },
  {
    path: '/lineList',
    name: 'LineLists',
    meta: { title: '排队列表', isLogin: true },
    component: () => import('../views/line/lineList.vue')
  },
  {
    path: '/make/list',
    name: 'MakeList',
    meta: { title: '预约', isLogin: true },
    component: () => import('../views/make/makeAn.vue')
  },
  {
    path: '/user/list',
    name: 'userList',
    meta: { title: '预约列表', isLogin: true },
    component: () => import('../views/make/userList.vue')
  },
  {
    path: '/cate/add',
    name: 'CateAdd',
    meta: { title: '添加分类', isLogin: true },
    component: () => import('../views/cate/CateAdd.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    meta: { footer: true, title: '我的', isLogin: true },
    component: () => import('../views/mine/Mine.vue')
  },
  {
    path: '/setting',
    name: 'Setting',
    meta: { title: '设置', isLogin: true },
    component: () => import('../views/mine/Setting.vue')
  },
  {
    path: '/product/list',
    name: 'ProductList',
    meta: { title: '商品管理', isLogin: true },
    component: () => import('../views/product/ProductList.vue')
  },
  {
    path: '/order/list',
    name: 'OrderList',
    meta: { title: '订单管理', isLogin: true },
    component: () => import('../views/order/OrderList.vue')
  },
  // {
  //   path: '/order/info',
  //   name: 'OrderInfo',
  //   meta: { title: '订单详情', isLogin: true },
  //   component: () => import('../views/order/OrderInfo.vue')
  // },
  {
    path: '/order/detail',
    name: 'orderDetail',
    meta: { title: '订单详情', isLogin: true },
    component: () => import('../views/order/orderDetail.vue')
  },
  {
    path: '/Discount',
    name: 'Discount',
    meta: { title: '优惠券列表', isLogin: true },
    component: () => import('../views/mine/Discount.vue')
  },
  {
    path: '/DiscountDetails',
    name: 'DiscountDetails',
    meta: { title: '优惠券详情', isLogin: true },
    component: () => import('../views/mine/DiscountDetails.vue')
  },
  {
    path: '/addDiscount',
    name: 'addDiscount',
    meta: { title: '添加优惠券', isLogin: true },
    component: () => import('../views/mine/addDiscount.vue')
  },
  // {
  //   path: '/product/info',
  //   name: 'OrderInfo',
  //   meta: { title: '订单详情', isLogin: true },
  //   component: () => import('../views/order/OrderInfo.vue')
  // },
  {
    path: '/product/add',
    name: 'ProductAdd',
    meta: { title: '添加商品', isLogin: true },
    component: () => import('../views/product/ProductAdd.vue')
  },
  {
    path: '/chat',
    name: 'ChatList',
    meta: { footer: true, title: '客服列表', isLogin: true },
    component: () => import('../views/chat/ChatList.vue')
  },
  {
    path: '/chat/page',
    name: 'ChatPage',
    meta: { title: '客服窗口', isLogin: true },
    component: () => import('../views/chat/ChatPage.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { footer: false, title: '登录'},
    component: () => import('../views/Login.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    meta: { footer: false, title: '404' },
    component: () => import('../views/404/404.vue')
  },
  {
    path: '/editShop',
    name: 'editShop',
    meta: { title: '编辑门店', isLogin: true },
    component: () => import('../views/mine/editShop.vue')
  },
  {
    path: '/business',
    name: 'Business',
    meta: { title: '营业设置', isLogin: true },
    component: () => import('../views/mine/Business.vue')
  },
  {
    path: '/appoitTime',
    name: 'appoitTime',
    meta: { title: '预约时间段', isLogin: true },
    component: () => import('../views/mine/appoitTime.vue')
  },
  {
    path: '/configFiles',
    name: 'configFiles',
    meta: { title: '文件配置', isLogin: true },
    component: () => import('../views/mine/configFiles.vue')
  },
  {
    path: '/choice',
    name: 'choice',
    meta: {
     title: '选择用户类型',
     footer: false, 
     isLogin: true 
    },
    component: () => import('../views/choice/index.vue')
  },
  {
    path: '/statistics',
    name: 'statistics',
    meta: {
     title: '商户数据统计',
     footer: true, 
     isLogin: true 
    },
    component: () => import('../views/statistics/index.vue')
  },
  {
    path: '/store',
    name: 'store',
    meta: {
     title: '门店列表',
     footer: true, 
     isLogin: true 
    },
    component: () => import('../views/store/index.vue')
  },
  {
    path: '/store/addStore',
    name: 'addStore',
    meta: {
     title: '门店列表',
     footer: true, 
     isLogin: true 
    },
    component: () => import('../views/store/addStore.vue')
  },
  {
    path: '/choice/supplierAllBack',
    name: 'supplierAllBack',
    meta: {
     title: '空页面',
     footer: false, 
     isLogin: false 
    },
    component: () => import('../views/choice/supplierAllBack.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, form, next) => {
  const { title, footer, isLogin } = to.meta
  // 是否需要登录
  if (isLogin === true && !$store.state.user.isLogin) {
    $store.dispatch('ToLogin', buildUrl(to.href))
  }
  // 标题
  document.title = title
  // 底部导航是否显示
  footer === true ? $store.commit('SHOW_FOOTER') : $store.commit('HIDE_FOOTER')
  next()
})

export default router
