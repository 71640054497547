const state = {
  footer: true
}

const mutations = {
  SHOW_FOOTER(state) {
    state.footer = true
  },
  HIDE_FOOTER(state) {
    state.footer = false
  }
}

const actions = {

}

export default {
  state,
  mutations,
  actions
}
