<template>
	<div>
		<router-view />
		<Footer v-show="footer" />
		<!--    <float-button v-show="!footer" />-->
	</div>
</template>
<script>
	import Footer from '@/components/Footer'
	// import FloatButton from "@/components/FloatButton"
	import {
		mapGetters
	} from 'vuex'
	import upsdk from 'upsdk-vue'
	import {
		config
	} from "@/api/login";
	import {
		isUnionPay
	} from '@/utils/tools'
	import WebSocket from '@/utils/socket'
	import mitt from 'mitt'

	export default {
		components: {
			Footer
		},
		computed: {
			...mapGetters([
				'footer'
			])
		},
		data() {
			return {
				ws: null,
				mitt: null
			}
		},
		mounted() {
			// 云闪付环境配置
			if (isUnionPay()) {
				config({
					url: window.location.href
				}).then((res) => {
					upsdk.config({
						appId: res.data.appId,
						timestamp: res.data.timestamp,
						nonceStr: res.data.nonceStr,
						signature: res.data.signature,
						debug: res.data.debug
					})
				})
				// 标题颜色
				upsdk.ready(function() {
					upsdk.setTitleStyle({
						navBackgroundColor: '0x8FFFFFFF',
						appletStyle: 'black',
						backBtnVisible: 1
					})
				});
			}
			// this.ws = new WebSocket()
			// this.mitt = mitt()
			// this.ws.channel(this.mitt)
			// this.mitt.on('message', data => {
			//   this.messagePush(data)
			// })
		},
		methods: {
			messagePush(data) {
				console.log("收到消息", data)
			}
		}
	}
</script>
<style>
	#app {
		background: #f6f6f6;
	}

	.van-swipe-cell__wrapper {
		height: 100%;
	}
	/* .van-swipe-cell__left, .van-swipe-cell__right{
		top: 25% !important;
	} */
</style>
